@import "definitions";

/*Hauptnavigation*/
$nav_bg: #000;                                              // Hauptnavigation Hintergrundfarbe
$nav_font_color: #fff;                                      // Hauptnavigation Schriftfarbe
$nav_height: 3.35em;                                        // Höhe Hauptnavigation
$nav_pad_item: 1em 2em 0.95em;                              // Hauptnavigation Padding Navigationspunkt
$nav_item_hover_color: $highlight_color;                    // Farbe zum hervorheben eines Navigationspunktes
$nav_item_hover_font_color: $highlight_font_color;          // Schriftfarbe eines hervorgehobenen Navigationpunktes
$nav_item_active_color: $theme_color;                       // Hintergrundfarbe eines ausgewählten Navigationspunktes
$nav_item_active_font_color: $theme_font_color;             // Schriftfarbe eines ausgewählten Navigationspunktes
$mar_top_logout_icon: 1.2em;                                // Abstand oben, Logout icon

/*2.Navigationsleiste*/
$second_nav_bg: $theme_color;                               // Hintergrund 2.Navigation
$second_nav_font_color: $theme_font_color;                  // Schriftfarbe 2.Navigation
$second_nav_height: $nav_height;                            // Höhe 2.Navigation
$second_nav_items_height: 2.3em;                              // Höhe der Navigationspunkte - 2.Navigation
$second_nav_arrow_icon_font_size: 2em;                      // Pfeil Icon Größe - 2.Navigation
$second_nav_arrow_icon_color: $theme_color_light;           // Pfeil Icon Farbe 
$second_nav_arrow_icon_mar_top: -8px;                       // Abstand nach oben 
$second_nav_pad_item: 0.5em 2em 0.5em 2em;                      // 2.Navigation Padding Navigationspunkt
$second_nav_pad_separator: 0.5em 1em 0.5em 1em;                 // 2.Navigation Padding Separator
$nav_border_special: 1px solid #C4C4C4;                   // Navigation spezielle Linie

/*Dropdown*/
$nav_dropdown_bg: $theme_color_light;                       // Hintergrundfarbe des Dropdown
$nav_dropdown_separator_bg: darken($theme_color_light, 10%);// Hintergrundfarbe Separator im Dropdown
$nav_dropdown_font_color: $theme_font_color_light;          // Schriftfarbe im Dropdown
$nav_dropdown_items_height: $second_nav_items_height;       // Höhe der einzelnen Navigationspunkte im Dropdown
$nav_dropdown_pad_item: 1em 2em;                            // Padding eines einzelnen Navigationspunkts im Dropdown
$nav_dropdown_item_hover_bg: $nav_item_hover_color;         // Hover Hintergrundfarbe eines Navigatiospunktes im Dropdown
$nav_dropdown_item_hover_font_color: $nav_item_hover_font_color; // Hover im Dropdown Schriftfarbe

/*Seitenleiste*/
$side_nav_width: 20em;                                      // Breite Navigation Seitenleiste 
$side_nav_bg: $main_bg;                                     // Hintergrundfarbe Seitenleiste
$side_nav_font_color: $theme_font_color_light;              // Farbe der Icons in der Seitenleiste
$side_nav_icon_font_color: $theme_color;                    // Iconfarbe in der Seitenleiste  
$side_nav_hover_color: $theme_color;                        // Hover Farbe der Navigationspunkte in der Seitenleiste
$side_nav_hover_font_color: $theme_font_color;              // Schriftfarbe und Iconfarbe der gehoverten Navigationspunkte
$side_nav_box_text_indent: 2.2em;                           // Texteinrückung eines Teilbereichs               
$side_nav_content_text_indent: 4.2em;                       // Texteinrückung für den Inhalt eines Teilbereichs
$side_nav_setting_icon_font_size: 1.3em;                    // Größe für das Kontextmenü - Icon
$side_nav_settings_width: 9em;                              // Breite des "Bearbeiten" - Menü in der Seitenlseite
$side_nav_setting_menu_spacing: 6em;                        // Abstand des Kontext Menü Dropdown
$side_nav_item_spacing_left: 1.2em;                         // Einrückung der Navigationspunkte

/*Benuterwechsel*/
$user_switch_mar_top: 0.9em;                                // Abstand nach oben von dem Benutzerwechsel dropdown

/*Hauptnavigation*/

div.main_nav{
    white-space: nowrap;
    color: $nav_font_color;
    background-color: $nav_bg;
    //height: $nav_height;
    width: 100%;
    max-width: 100%;
    box-shadow: $main_box_shadow;

    div.top {
        display:flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    span.nav_icon { 
        @include sl-icon($fa-var-bars); 
        visibility: hidden;
        display: none;
    } 

    .cms_logo {
        height: 30px;
    }

    ul.nav_items{
        
        //display: block;
        display: flex;
        align-items: center;
        white-space: nowrap; 

        &:first-child{
            overflow-x:auto;
            //width:100%;
        }

        > li {
            //min-height: $nav_height;
            display:inline-block;
            vertical-align: top;
            cursor: pointer;
            > a{
                padding: $nav_pad_item;                
            }
            .logout{
                @include sl-icon($fa-var-power-off);
                right: $mar_big;
                top: $mar_top_logout_icon;
                position: absolute;
            } 
            &:last-child:hover{
                background-color: transparent;
            }
            &:hover{
                background-color: $nav_item_hover_color;
                color: $nav_item_hover_font_color;
            }
            > a, > span{
                display: inline-block;
                color: $nav_font_color;
                text-transform: uppercase;
            }     
            &.highlight,
            &.active{
                background-color: $nav_item_active_color; 
                color: $nav_item_active_font_color;
            }

            ul.dropdown, ul.dropdown2 {
                display: none;
                background-color: $nav_dropdown_bg;
                position: absolute;
                z-index:10000;
                box-shadow: $main_box_shadow;
                >li {
                    vertical-align: middle;
                    border-bottom: $nav_border_special;
                    > a, > span{
                        color: $nav_dropdown_font_color;
                        display: block;
                        padding: $nav_dropdown_pad_item;
                        text-decoration: none;
                    }
                    &:hover:not(.structure), &:focus:not(.structure){
                        background-color: $nav_dropdown_item_hover_bg;
                        >a, >span{
                            color: $nav_dropdown_item_hover_font_color;
                        }
                    }
                    &.structure {
                        cursor: auto;
                        background: $nav_dropdown_separator_bg;

                        > span {
                            padding: .7em;
                        }
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }

            ul.dropdown {
                //left:0;
                top: $nav_height - 0.05;
            }

            ul.dropdown2 {
                left:100%;
                margin-top:-2.2em;
            }

            &:hover > ul.dropdown, ul.dropdown>li:hover > ul.dropdown2 {
                display:block;
            }
        }
    }

    ul.second_nav {
        display: block;
        position: absolute;
        top: $nav_height;
        left: 0;
        z-index: 0;
        height: $second_nav_height;
        background-color: $second_nav_bg;
        color: $second_nav_font_color;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        box-shadow: $main_box_shadow;
        overflow:hidden;
        
        li{
            display: inline-block;
            vertical-align: middle;
            position: relative;
            height: $second_nav_items_height;
            padding-top: $pad_small;
            padding-bottom: $pad_small;

            > a, > span{
                color: $nav_font_color;
                display: inline-block;
                padding: $second_nav_pad_item;
                text-decoration: none;
                border-right: $nav_border_special;
            }
            div.arrow{
                @include sl-icon($fa-var-caret-up);  
                display: none;
                text-align: center;
                visibility: hidden;
            }
            &.structure .separator {
                min-height: 1.4em;
                padding: $second_nav_pad_separator;
            }
            &:hover, &.active{
                div.arrow{
                    display: block;
                    visibility: visible;
                    font-size: $second_nav_arrow_icon_font_size;
                    color: $second_nav_arrow_icon_color;
                    position: absolute;
                    bottom: $second_nav_arrow_icon_mar_top;
                    left: 50%;
                    transform: translateX(-50%);
                }                        
            }

            &.active {
                background-color: darken($second_nav_bg, 9%);
            }

            &.navScroll {
                display: inline-flex;
                align-items: center;
                padding: $pad_small;
                position: sticky;
                z-index: 10;
                background-color: rgba(100, 101, 104, 0.7);
                opacity: .4;

                &.active {
                    opacity: 1;
                }

                &.scrollLeft {
                    left: 0;
                }

                &.scrollRight {
                    right: 0;
                }
            }
        }
    }
}



/*Blocknav*/

ul.blocknav {    display: inline-block;
    padding: 0;
    list-style: none;
    margin: 0 0 0 $mar;
    li{
        display: inline-block;
        padding: 0;
        &.prev i{
            @include sl-icon($fa-var-angle-double-left);
            margin-right: $mar_small;
        }
        &.next i{
            @include sl-icon($fa-var-angle-double-right);         
            margin-left: $mar_small;
        }
        > a{
            cursor: pointer;
            padding: $pad_small;
            margin-right: $mar_tiny;
            text-align: center;
            min-width: $pad_small*3;
            display: inline-block;
            border: 1px solid transparent;
            &.active, &:hover{
                //background: $theme_color;   
                border: $border;
                //color: $theme_font_color;
            }
        }
    }
}

/*Nav Seitenleiste*/

div.sidebar {
    vertical-align: top;
    display: table-cell;
    div.sidebar_inner { 
        &:not(.no_box) {
            border-top: $border;
            border-left: none;
            box-shadow: $main_box_shadow;
        }

        width: $side_nav_width;

        div.sidebar_box {
            overflow:hidden;
            padding: $pad 0 $pad_small;
            background: $side_nav_bg;
            text-indent: $side_nav_box_text_indent;
            .box_heading { 
                text-indent: 0;
                display: inline-block;
                color: $side_nav_font_color;
                padding: 0;
                font-size: $font_size_heading;
                .cat{
                    @include sl-icon($fa-var-th-large);
                    color: $side_nav_icon_font_color;
                }
                .tools{ 
                    @include sl-icon($fa-var-wrench);
                    color: $side_nav_icon_font_color;
                }
                .datachange{
                    @include sl-icon($fa-var-refresh);
                    color: $side_nav_icon_font_color;
                }
                .configuration{
                    @include sl-icon($fa-var-cog);
                    color: $side_nav_icon_font_color;
                }
                .templates{
                    @include sl-icon($fa-var-file);
                    color: $side_nav_icon_font_color;
                }
                .add{
                    @include sl-icon($fa-var-plus-circle);
                    color: $side_nav_icon_font_color;
                }
                .textmanager{
                    @include sl-icon($fa-var-list);
                    color: $side_nav_icon_font_color;
                }
            }
            .arrow_up, .arrow_down{
                float: right;
                font-size: $font_size_heading;                    
                color: $side_nav_icon_font_color;  
                margin-top: $mar_tiny;
                margin-right: $mar;
            }
            .box_content{  
                margin-top: $mar_small;
                text-indent: $side_nav_content_text_indent;
            }
        } 

        &.version {
            padding: $pad_small;
            color: #ccc;
        }        
    }

    .sidebar_container {
        position:relative;
    }

    .sidebar_grip {
        position: absolute;
        z-index:10;
        top: 0;
        right: -1.5em;
        bottom: 0;
        width:1em;
        >div {
            visibility:hidden;
            position:absolute;
            top:50%;
            transform: translateY(-50%);
            @include sl-icon($fa-var-arrows-h);
        }

        &:hover {
            background-color: #ddd;

            &>div {
                visibility:visible;
            }
        }
    }

    &.collapsed {
        .sidebar_inner {
            display:none;          
        }      

        .sidebar_grip {
            left:0;
            right: initial;
            bottom: initial;
            height:1em;
            background-color: #a8a5a1;
            color:#fff;
            padding:.3em .1em .3em .3em;
            
            >div {
                visibility: visible; 
                @include sl-icon($fa-var-chevron-right);
            }
        }
    }
}

/*normaler Kategoriebaum*/

ul.fixed_tree{
    position:relative;
    li{
        > ul > li{
            padding-left: $side_nav_item_spacing_left;
        }
        > a{
            position:relative;
            display: block;
            padding: $pad_small 0; 
            z-index: 1;
        }
        &:hover > a, &.active > a{
            background-color: $side_nav_hover_color;
            color: $side_nav_hover_font_color;
        }

        &.active,
        &.hover{
            &:before {
                position:absolute;
                left:0;
                right:0;
                height: 2.5em;
                background: $side_nav_hover_color;
                content:"";
                z-index:1;
            }
        }
    }
} 

/*Sitemanager / Kategoriebaum*/

div.expandable_tree {
    position:relative;
    ul.expandable_tree{ 
        display: block;
        li {
            display: block;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            line-height: $pad_big;
            > div.item{   
                position: relative;

                /* states for sitemanager */
                &.hidden > a{
                    //ausgeblendet
                    color: #8989FF;
                }

                &.disabled > a {
                    //gesperrt
                    color: #EC6B4E;
                }

                &.locked > a{
                    //löschgeschützt
                    font-style: italic;
                }

                &.password > a{
                    //passwordgeschützt
                    color: #ff9400
                }

                /* extra icons for some types of pages */
                &.linked, &.structure {
                    //verlinkt, strukturseite
                    > span.additional_icon {
                        .linked {
                            @include sl-icon($fa-var-link);
                            display: inline;
                        }

                        .structure {
                            @include sl-icon($fa-var-clipboard);
                            display: inline;
                        }
                    }
                }

                > a {
                    display: inline-block;
                    cursor: pointer;
                    text-indent: 0;
                    width: 100%;
                }

                > span.tree {
                    > .open{
                        @include sl-icon($fa-var-plus-square);
                        cursor: pointer;
                        text-indent: 0;
                        color: $side_nav_icon_font_color;
                        margin-right: $mar_tiny;
                    }   
                    > .close{
                        @include sl-icon($fa-var-minus-square);
                        cursor: pointer;
                        text-indent: 0;
                        color: $side_nav_icon_font_color;
                        margin-right: $mar_tiny;
                        float: none;
                    }
                    > .pick{
                        @include sl-icon($fa-var-search-plus);
                        cursor: pointer;
                        text-indent: 0;
                        color: $side_nav_icon_font_color;
                        margin-right: $mar_small;
                        float: none;
                    }
                    > .empty{
                        @include sl-icon($fa-var-square-o);
                        text-indent: 0;
                        color: $side_nav_icon_font_color;
                        margin-right: $mar_tiny;
                    }
                    > .folder{
                        display:none;
                        /*@include sl-icon($fa-var-folder);
                        text-indent: 0;
                        color: $theme_color;*/
                    }
                }

                .action{
                    .settings{
                        @include sl-icon($fa-var-cog);
                        margin-bottom: $mar_tiny;
                        cursor: pointer;
                        position: absolute;
                        top: $mar_small;
                        font-size: $side_nav_setting_icon_font_size;
                        right: $mar;
                        color: $side_nav_hover_font_color;
                    }
                    visibility: hidden;
                    text-indent: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: $side_nav_settings_width;

                    ul {
                        display:none;
                    }

                    &.active{
                        visibility: visible;
                    }
                }
            }
            &.active,
            &.hover{
                > div.item > a{
                    color: $side_nav_hover_font_color;
                }

                > div.item > span.tree{
                    > .open,
                    > .close,
                    > .empty,
                    > .folder{
                        color: $side_nav_hover_font_color;
                    }    
                }

                > div.item > span.action{
                    visibility: visible;
                }
                &:before {
                    position:absolute;
                    left:0;
                    right:0;
                    height: 2.5em;
                    background: $side_nav_hover_color;
                    content:"";
                }
            }

            > ul {
                display:none;                
            }

            &.expanded > ul {
                display:block;
            }

            > ul > li{
                padding-left: $side_nav_item_spacing_left;
            }
        }
    }  
}

#tooltips>ul{
    border: $border;
    border-bottom: 0;
    padding: 0;
    z-index: 500;
    li{
        white-space: nowrap;
        line-height: $pad_big;
        list-style: none;
        background: $full_bg;
        padding-left: $pad_small;
        border-bottom: $border;
        color: $base_font_color;
        cursor: pointer;
        min-width:5em;
        padding-right:.5em;
        &:hover{
            background-color: $theme_color;
            color: $theme_font_color;
        }
        > i{
            &.add{
                @include sl-icon($fa-var-plus); 
            }
            &.edit{
                @include sl-icon($fa-var-edit); 
            }
            &.delete{
                @include sl-icon($fa-var-remove); 
            }
            &.copy{
                @include sl-icon($fa-var-files-o); 
            }
            &.up{
                @include sl-icon($fa-var-arrow-up); 
            }
            &.down{
                @include sl-icon($fa-var-arrow-down); 
            }
        }
    }
}


/*Benutzerauswahl*/

.user_switch{
    padding: $nav_pad_item;
    .down{
        @include sl-icon($fa-var-angle-down); 
        margin-right: $mar_tiny;
    }
}


.user_switch_dropdown{
    position: absolute;
    z-index: 10; 
    right: 0;
    margin-top: $user_switch_mar_top;
    background-color: $nav_bg;
    > div{
        padding: $pad_small;
        &:hover{
            background: $nav_item_hover_color;
            color: $nav_item_hover_font_color;
        }
    }
}

.language_switch{
    position:relative;
    &>span {
        display:inline-block;
        padding: $nav_pad_item;
    }
    .down{
        @include sl-icon($fa-var-angle-down); 
        margin-right: $mar_tiny;
    }

    .language_switch_dropdown {
        display:none;
        position: absolute;
        z-index: 10; 
        right: 0;
        top:100%;
        //margin-top: $user_switch_mar_top;
        background-color: $nav_bg;
        > div{
            padding: $nav_pad_item;
            &:hover{
                background: $nav_item_hover_color;
                color: $nav_item_hover_font_color;
            }
        }
    }
}